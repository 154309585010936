var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('search-box',{attrs:{"label":"* Órgão","popup-label":"Selecione um Órgão","id":_vm.value.idOrgao,"descricaoFormatada":_vm.value.descFormatadaOrgao,"hidden-clear":true,"value":_vm.orgaos,"loader-fn":_vm.loaderOrgaos,"item-key":"id","item-text":"descricaoFormatada","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idOrgao", $event)},"update:descricaoFormatada":function($event){return _vm.$set(_vm.value, "descFormatadaOrgao", $event)},"update:descricao-formatada":function($event){return _vm.$set(_vm.value, "descFormatadaOrgao", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Órgão encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":"* Código","dense":"","rules":[
                      this.$validators.number.required,
                        function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                        function (v) { return this$1.$validators.string.lessThanOrEquals(v, 5); }
                      ]},model:{value:(_vm.value.cdUnidade),callback:function ($$v) {_vm.$set(_vm.value, "cdUnidade", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cdUnidade"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"* Descricao","dense":"","rules":[
                      this.$validators.string.required,
                        function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                        function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                      ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }